<template>
  <div class="autocomplete-2">
    <label class="autocomplete-label" v-if="caption">{{ caption }}</label>
    <add-item
      v-if="canAdd"
      @click="addConfirm"
      style="right: 25px; top: 18px"
    ></add-item>
    <v-select
      v-model="model"
      :options="source"
      :placeholder="placeholder"
      :disabled="disabled"
      :class="{ 'alert-danger': alert }"
    ></v-select>
    <add-entity-modal
      v-if="addDialogActive"
      @close="closeAddDialog"
      @save="saveAddDialog"
      :caption="caption"
    ></add-entity-modal>
  </div>
</template>

<script>
import vSelect from 'vue-select';

import AddItem from '../AddItem';
import AddEntityModal from './addEntityModal';

export default {
  data: function() {
    return {
      addDialogActive: false
    };
  },
  props: {
    caption: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      default: 'Начинайте вводить для поиска'
    },
    source: {
      type: Array,
      required: true
    },
    initialValue: {
      type: [String, Number],
      required: false
    },
    canAdd: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    alert: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    addConfirm() {
      this.addDialogActive = true;
    },
    closeAddDialog() {
      this.addDialogActive = false;
    },
    saveAddDialog(text) {
      this.$emit('addItem', text);
      this.addDialogActive = false;
    }
  },
  computed: {
    model: {
      get: function() {
        if (!this.initialValue && this.source.length === 0) {
          return null;
        }
        // eslint-disable-next-line eqeqeq
        const [result] = this.source.filter(
          // eslint-disable-next-line eqeqeq
          item => item.value == this.initialValue
        );
        return result;
      },
      set: function(val) {
        this.$emit('selected', val);
      }
    }
  },

  components: {
    vSelect,
    AddItem,
    AddEntityModal
  }
};
</script>
<style scoped lang="scss">
@import '~bootstrap/scss/bootstrap.scss';

.lighted {
  color: red;
  font-weight: bold;
}

.autocomplete.ok {
  border: 3px #dfd solid;
}

.edit {
  cursor: pointer;
  text-decoration: underline;
  color: #77f;
  background-color: #eee;
  border: 1px #ccc dotted;
  border-radius: 5px;
  padding: 8px;
  font-size: 16px;
}

.disabled,
.disabled input {
  background-color: #eee;
}

.autocomplete-2 .dropdown-toggle::after {
  content: none;
}

.autocomplete-label {
  text-transform: uppercase;
  color: #ccccd4;
}
</style>
